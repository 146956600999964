
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SelectFilter from './SelectFilter.vue';
import SelectFilterContainer from './SelectFilterContainer.vue';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		SelectFilter,
	},
})
export default class AgentSelectFilter extends SelectFilterContainer {
	@Prop({ default: false }) public isShowCode!: boolean;

	public get options() {
		const list = this.isShowCode ? gdbx.allAgentsCodeOnly : gdbx.allAgents;
		if (
			list.length > 0 &&
			(this.selected === null || this.selected === undefined)
		) {
			this.selected = list[0].value;
		}
		return list;
	}

	@Watch('selected', { immediate: true })
	public onValueChange(newVal) {
		this.$emit('returnSelectedAgent', newVal);
	}
}
