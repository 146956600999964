
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SelectFilter from './SelectFilter.vue';
import BaseFilter from './BaseFilter.vue';
import { ValueText } from '@/store/models.def';

@Component({
	components: {
		SelectFilter,
	},
})
export default class SelectFilterContainer extends BaseFilter {
	@Prop({ default: '' }) public value!: string;

	public selected: string = this.value;

	public get options(): ValueText[] {
		return [];
	}

	public onHovering(value: boolean, id: string) {
		this._emitIsHovering(value);
	}
	public onEditing(value: boolean, id: string) {
		this._emitIsEditing(value);
	}
	// public get validSelected(): string[] {
	// 	return this.value.length === 0 && this.options.length > 0
	// 		? this.options.slice()
	// 		: this.value;
	// }
	@Watch('selected')
	public onSelectedChange(newVal: string, oldVal: string) {
		if (this.selected !== this.value) {
			this.$emit('input', this.selected);
		}
	}
	@Watch('value')
	public onValueChange(newVal: string, oldVal: string) {
		this.selected = this.value;
	}
	// @Watch('options')
	// public onOptionsChange(newVal: string[], oldVal: string[]) {
	// 	this.selected = this.value;
	// }
}
