
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	CustomerIvDnCard,
	CustomerKoCard,
	CustomerSupplierDbCard,
	PermissionsGroup,
	ValueText,
} from '@/store/models.def';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';

@Component({
	components: { TableFilterable, MultiSelect, Checkbox },
})
export default class CreditLimitTable extends FilterWidget {
	@Prop({ default: '' }) public readonly selectedAgent!: string;

	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public tableItems: Array<{
		customer: string;
		total_sales: number;
		sales_month_count: number;
		average_sales: number;
		due_inv_count: number;
		late_due_inv_count: number;
		late_percentage: number;
		tier: string;
		recommend_limit: number;
		current_limit: number;
		new_limit: number | null;
		last_review: number | null;
		status: string;
	}> = [];
	public tableFields: any[] = [];

	public isShowExtraDetails: boolean = false;
	public isOnlyFilledNewLimit: boolean = false;
	public selectedStatus: string[] = [];
	public selectedStatusTypeList: Array<ValueText<string>> = [];

	@Watch('selectedStatusTypeList', { immediate: true })
	public onSelectedStatusTypeListChanged() {
		this.selectedStatus = this.selectedStatusTypeList.map((vt) => vt.value);
	}

	public get exportFileName() {
		const formattedDate = [
			moment(this.selectedDateRange[0]).format('DD MMM YY'),
			moment(this.selectedDateRange[1]).format('DD MMM YY'),
		];
		return (
			'Credit Limit Analysis' + '_' + formattedDate[0] + ' to ' + formattedDate[1]
		);
	}

	public get statusType() {
		return this.selectedStatusTypeList;
	}

	public get showFilledNewLimitItems() {
		const newItems: any[] = [];

		if (this.isOnlyFilledNewLimit) {
			this.tableItems.forEach((item) => {
				if (
					item.hasOwnProperty('new_limit') &&
					(item.new_limit || item.new_limit === 0)
				) {
					if (this.selectedStatus.includes(item.status)) {
						newItems.push(item);
					}
				}
			});

			return newItems.length > 0
				? newItems
				: this.tableItems.filter((item) =>
						this.selectedStatus.includes(item.status),
				  );
		} else {
			return this.tableItems.filter((item) =>
				this.selectedStatus.includes(item.status),
			);
		}
	}

	public get showExtraDetailFields() {
		return this.isShowExtraDetails
			? [
					{
						key: 'customer',
						sortable: true,
						tdClass: (value) => {
							if (value) {
								return 'custom-style';
							}
						},
						stickyColumn: true,
					},
					{
						key: 'total_sales',
						sortable: true,
						formatter: TableItemFormatter.currency,
						variant: 'info',
					},
					{
						key: 'sales_month_count',
						label: 'Mth w Sales',
						sortable: true,
						variant: 'info',
					},
					{
						key: 'average_sales',
						label: 'TTM Av. Mthly Sales',
						sortable: true,
						formatter: TableItemFormatter.currency,
					},
					{
						key: 'due_inv_count',
						label: 'Due Invoice',
						sortable: true,
						variant: 'info',
					},
					{
						key: 'late_due_inv_count',
						label: 'Late On Due Invoice',
						sortable: true,
						variant: 'info',
					},
					{
						key: 'late_percentage',
						label: '% Late',
						sortable: true,
						variant: 'info',
					},
          {
            key: 'previous_tier',
            sortable: true,
          },
					{
						key: 'tier',
						sortable: true,
					},
					{
						key: 'recommend_limit',
						sortable: true,
						formatter: TableItemFormatter.currency,
					},
					{
						key: 'current_limit',
						sortable: true,
						formatter: TableItemFormatter.currency,
					},
					{
						key: 'new_limit',
						sortable: true,
					},
					{
						key: 'last_review',
						sortable: true,
						formatter: TableItemFormatter.date3,
					},
			  ]
			: [
					{
						key: 'customer',
						sortable: true,
						tdClass: (value) => {
							if (value) {
								return 'custom-style';
							}
						},
						stickyColumn: true,
					},
					{
						key: 'average_sales',
						label: 'TTM Av.Sales - Per Month',
						sortable: true,
						formatter: TableItemFormatter.currency,
					},
          {
            key: 'previous_tier',
            sortable: true,
          },
					{
						key: 'tier',
						sortable: true,
					},
					{
						key: 'recommend_limit',
						sortable: true,
						formatter: TableItemFormatter.currency,
					},
					{
						key: 'current_limit',
						sortable: true,
						formatter: TableItemFormatter.currency,
					},
					{
						key: 'new_limit',
						sortable: true,
					},
					{
						key: 'last_review',
						sortable: true,
						formatter: TableItemFormatter.date3,
					},
			  ];
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgent, selectedDateRange } = this;
		return JSON.stringify([selectedAsOfDate, selectedAgent, selectedDateRange]);
	}

	public async expensiveCalc() {
		// let pa = 0.5;
		// let pb = 0;

		const globalRef = FilteredDatabase.ref('globalCustomers').agents([
			this.selectedAgent,
		]);

		const statusTypeList = [
			...(await (await globalRef.get()).getSet('status')),
		];

		this.selectedStatusTypeList = statusTypeList.map((item) => ({
			value: item,
			text:
				item === 'A'
					? 'ACTIVE'
					: item === 'N'
					? 'PENDING'
					: item === 'I'
					? 'INACTIVE'
					: item === 'P'
					? 'PROSPECT'
					: item === 'S'
					? 'SUSPEND'
					: item,
		}));

		const globalCards: CustomerSupplierDbCard[] = await (
			await globalRef.get()
		).getCards();

		const groupedGlobalCustomer = {};

		for (const global of globalCards) {
			if (!groupedGlobalCustomer[global.code]) {
				groupedGlobalCustomer[global.code] = [];
			}
			groupedGlobalCustomer[global.code].push(global);
		}

		const customerList: string[] = [
			...(await (await globalRef.get()).getSet('code')),
		];

		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.dateRange([
          this.selectedDateRange[0] - (31556926000),
          this.selectedDateRange[1],
      ])
			.customers(customerList);

		const dueIvdnRef = FilteredDatabase.ref('customerIvDns')
			.numberRange('dueDate', [
				['>=', this.selectedDateRange[0] - (31556926000)],
				['<=', this.selectedDateRange[1]],
			])
			.customers(customerList);

		const ivdnCards: CustomerIvDnCard[] = await (
			await ivdnRef.get()
		).getCards();

		const dueIvdnCards: CustomerIvDnCard[] = await (
			await dueIvdnRef.get()
		).getCards();

		const koRef = FilteredDatabase.ref('customerKos')
			.dateAsOf(this.selectedAsOfDate)
			.customers(customerList);

		const koCards: CustomerKoCard[] = await (await koRef.get()).getCards();

		const groupedIvdnCustomer = {};
		const groupedDueIvdnCustomer = {};
  const prevGroupedIvdnCustomer = {};
  const prevGroupedDueIvdnCustomer = {};

		for (const ivdn of ivdnCards.filter((item) => item.date > this.selectedDateRange[0])) {
			if (!groupedIvdnCustomer[ivdn.code]) {
				groupedIvdnCustomer[ivdn.code] = [];
			}
			groupedIvdnCustomer[ivdn.code].push(ivdn);
		}

  for (const ivdn of dueIvdnCards.filter((item) => item.date > this.selectedDateRange[0])) {
      if (!groupedDueIvdnCustomer[ivdn.code]) {
        groupedDueIvdnCustomer[ivdn.code] = [];
      }
      groupedDueIvdnCustomer[ivdn.code].push(ivdn);
    }

		for (const ivdn of ivdnCards.filter((item) => item.date < this.selectedDateRange[0])) {
			if (!prevGroupedIvdnCustomer[ivdn.code]) {
        prevGroupedIvdnCustomer[ivdn.code] = [];
			}
   prevGroupedIvdnCustomer[ivdn.code].push(ivdn);
		}

  for (const ivdn of dueIvdnCards.filter((item) => item.date < this.selectedDateRange[0])) {
			if (!prevGroupedDueIvdnCustomer[ivdn.code]) {
        prevGroupedDueIvdnCustomer[ivdn.code] = [];
			}
   prevGroupedDueIvdnCustomer[ivdn.code].push(ivdn);
		}

		const result: Array<{
			customer: string;
			total_sales: number;
			sales_month_count: number;
			average_sales: number;
			due_inv_count: number;
			late_due_inv_count: number;
			late_percentage: number;
			tier: string;
			recommend_limit: number;
			current_limit: number;
			last_review: number | null;
			new_limit: number | null;
			status: string;
		}> = [];

		for (const code in groupedGlobalCustomer) {
			if (
				groupedIvdnCustomer.hasOwnProperty(code) &&
				groupedDueIvdnCustomer.hasOwnProperty(code)
			) {
				const cards = groupedIvdnCustomer[code] as CustomerIvDnCard[];
				const dueCards = groupedDueIvdnCustomer[code] as CustomerIvDnCard[];

    const prevCards = prevGroupedIvdnCustomer[code] as CustomerIvDnCard[];
    const prevDueCards = prevGroupedDueIvdnCustomer[code] as CustomerIvDnCard[];

				const cards2 = groupedGlobalCustomer[code] as CustomerSupplierDbCard[];

				const dueCount = cards.length;
    const prevDueCount = prevCards ? prevCards.length : 0;

				const salesMonthCount = [
					...new Set(cards.map((ivdn) => moment(ivdn.date).format('MMM YY'))),
				].length;

    const prevSalesMonthCount = prevCards ? [
          ...new Set(prevCards.map((ivdn) => moment(ivdn.date).format('MMM YY'))),
        ].length : 0;

				const totalIvdn = cards.reduce((a, b) => a + b.amount, 0);

    const prevTotalIvdn = prevCards ? prevCards.reduce((a, b) => a + b.amount, 0) : 0;

				const averageSales = totalIvdn / salesMonthCount;

    const prevAverageSales = prevCards ? prevTotalIvdn / prevSalesMonthCount : 0;

				const object = {
					customer: gdbx.customerNames[code],
					total_sales: totalIvdn,
					sales_month_count: salesMonthCount,
					average_sales: averageSales,
					due_inv_count: 0,
					late_due_inv_count: 0,
					late_percentage: 0,
					tier: '',
					recommend_limit: 0,
          previous_due_inv_count: 0,
          previous_late_due_inv_count: 0,
          previous_late_percentage: 0,
          previous_tier: '',
					current_limit: cards2[0].creditLimit,
					last_review: cards2[0].lastReviewDate
						? cards2[0].lastReviewDate
						: null,
					new_limit: null,
					status: cards2[0].status,
				};

				let lateCount = 0;

    let prevLateCount = 0;

				dueCards.forEach((ivdn) => {
					const filteredKoCards = koCards.filter((ko) => ko.toDocNo === code);

					let lastKoDate = 0;

					if (filteredKoCards) {
						const koAmount = filteredKoCards
							.map((ko) => ko.amount + ko.gainLoss)
							.reduce((a, b) => a + b, 0);

						const outstanding = ivdn.amount - koAmount;

						if (outstanding >= 0.05) {
							lastKoDate = filteredKoCards.reduce(
								(prev, current) =>
									prev > current.koDate ? prev : current.koDate,
								1,
							);
						} else {
							lastKoDate = moment(this.selectedAsOfDate)
								.add(1, 'day')
								.valueOf();
						}

						if (moment(lastKoDate).diff(moment(cards[0].dueDate)) > 0) {
							lateCount += 1;
						}
					}
				});

    if (prevCards) {
          prevDueCards.forEach((ivdn) => {
            const filteredKoCards = koCards.filter((ko) => ko.toDocNo === code);

            let lastKoDate = 0;

            if (filteredKoCards) {
              const koAmount = filteredKoCards
                  .map((ko) => ko.amount + ko.gainLoss)
                  .reduce((a, b) => a + b, 0);

              const outstanding = ivdn.amount - koAmount;

              if (outstanding >= 0.05) {
                lastKoDate = filteredKoCards.reduce(
                    (prev, current) =>
                        prev > current.koDate ? prev : current.koDate,
                    1,
                );
              } else {
                lastKoDate = moment(this.selectedAsOfDate)
                    .add(1, 'day')
                    .valueOf();
              }

              if (moment(lastKoDate).diff(moment(cards[0].dueDate)) > 0) {
                prevLateCount += 1;
              }
            }
          });
        }

				const latePercentage = roundToFixed(
					(lateCount / dueCount) * 100,
					this.numDecimal,
				);

    const prevLatePercentage = roundToFixed(
            (prevLateCount / prevDueCount) * 100,
            this.numDecimal,
        );

				object.late_percentage = dueCount !== 0 ? latePercentage : 0;
				object.due_inv_count = dueCount;
				object.late_due_inv_count = lateCount;
				object.tier =
					Math.round(totalIvdn) === 0
						? 'N/A'
						: latePercentage <= 10
						? 'A'
						: latePercentage > 10 && latePercentage <= 20
						? 'B'
						: 'C';
				object.recommend_limit =
					latePercentage <= 10
						? Math.round((averageSales * 3) / 100) * 100
						: latePercentage > 10 && latePercentage <= 20
						? Math.round((averageSales * 2) / 100) * 100
						: Math.round(averageSales / 100) * 100;

    object.previous_late_percentage = prevDueCount !== 0 ? prevLatePercentage : 0;
    object.previous_due_inv_count = prevDueCount;
    object.previous_late_due_inv_count = prevLateCount;
    object.previous_tier =
            Math.round(prevTotalIvdn) === 0
                ? 'N/A'
                : prevLatePercentage <= 10
                    ? 'A'
                    : prevLatePercentage > 10 && prevLatePercentage <= 20
                        ? 'B'
                        : 'C';

				result.push(object);
			} else {
				const cards2 = groupedGlobalCustomer[code] as CustomerSupplierDbCard[];

				const object = {
					customer: gdbx.customerNames[code],
					total_sales: 0,
					sales_month_count: 0,
					average_sales: 0,
					due_inv_count: 0,
					late_due_inv_count: 0,
					late_percentage: 0,
          previous_tier: 'N/A',
					tier: 'N/A',
					recommend_limit: 0,
					current_limit: cards2[0].creditLimit,
					last_review: cards2[0].lastReviewDate
						? cards2[0].lastReviewDate
						: null,
					new_limit: null,
					status: cards2[0].status,
				};
				result.push(object);
			}
		}
		this.tableItems = result;
		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
