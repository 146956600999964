
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CreditLimitTable from '@/components/reports-v2/components/codedWidgets/recommendation/CreditLimitTable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import gdbx from '@/store/modules/gdbx';
import LastSync from '@/components/LastSync.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import AgentSelectFilter from '@/components/reports-v2/components/filters/AgentSelectFilter.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';

@Component({
	components: {
		DateFilter,
		LastSync,
		CreditLimitTable,
		SelectFilter,
		AgentSelectFilter,
		StickyFilterContainer,
	},
})
export default class CreditLimit extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedAgent: string = 'FANNY IP';

	@Watch('selectedAgents')
	public onSelectedAreasChanged(newVal) {
		this.selectedAgent = newVal[19];
	}

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedDateRange(): [number, number] {
		return [
			moment(this.selectedDay)
				.add(1, 'day')
				.add(-12, 'month')
				.startOf('day')
				.valueOf(),
			moment(this.selectedDay).endOf('day').valueOf(),
		];
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('day').valueOf();
	}

	public get selectedAgents() {
		return gdbx.allAgents.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
